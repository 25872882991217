<template>
  <GenerateQuotation :isInvoice="true" />
</template>
<script>
import GenerateQuotation from '../Quotations/Generate.vue'
export default {
  components: {
    GenerateQuotation,
  },
}
</script>
